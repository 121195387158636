import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="contact">
            <div className="wrapper contact">
              <header>
                <h1 className="logo">
                  <a href="/">Neutrogena</a>
                </h1>
                <nav>
                  <a href="/produkty/" className="link">
                    Produkty
                  </a>
                  <span>| </span>
                  <a href="/neutrogena/o-nas/" className="link">
                    NEUTROGENA<sup>®</sup>
                  </a>
                  <span>| </span>
                  <a href="/budte-krasna/" className="link">
                    Buďte krásná
                   </a>
                   
                  <span />
                   
                  <span />
                </nav>
              </header>
              <div className="contaCtcontent"></div>
            </div>
            <div className="wrapper">
              <div className="content">
                <section className="main">
                  <div className="text full">
                    <div>
                      <h2>Jak nás kontaktovat</h2>
                      <br />
                      <br />
                      <br />
                      <br />
                      <br />
                      <br /> <br />
                      <div
                        style={{
                          width: "960px",
                          border: "0px solid #cc339b",
                          height: "210px",
                          "padding-top": "20px"
                        }}
                        align="left"
                      >
                        <p>
                          Kontaktujte nás prostřednictvím formuláře (
                          <a
                            href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p6"
                            target="_blank"
                          >
                            zde)
                          </a>
                          .
                        </p>
                        <p>
                          infolinka: <strong>00800 555 220 00</strong>
                          &nbsp;(volání zdarma, hovory z mobilní sítě mohou být
                          zpoplatněny dle tarifu operátora),&nbsp;v pracovních
                          dnech PO-PÁ, v čase 9:00-13:00 hod.
                        </p>
                        <p>
                          Vámi poskytnuté osobní údaje budou zpracovány v
                          souladu s našimi Zásadami ochrany osobních
                          údajů&nbsp;a to za účelem vyřízení Vašeho požadavku.
                          Vaše osobní údaje bude zpracovávat společnost
                          JNTL Consumer Health (Czech Republic) s.r.o., jakožto
                          správce osobních údajů a dále společnosti Sitel Europe
                          Limited, identifikační číslo
                          GB&nbsp;672&nbsp;54&nbsp;34&nbsp;27, se sídlem
                          Building 600, Leavesden Business Park, Hercules Way,
                          Watford, Spojené království Velké Británie a Severního
                          Irska a Sitel Bulgaria EOOD, identifikační číslo BG
                          175054504, se sídlem Blvc. N. Vapzarov 55, GB-1407
                          Sofie, Bulharsko jakožto správcem určení zpracovatelé
                          osobních údajů. Veškeré osobní údaje jsou poskytovány
                          dobrovolně.
                        </p>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
